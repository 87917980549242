import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BASE_PATH as BPM_BASE_PATH } from '@data-access/bpm-generated';
import { DS_SPECIAL_PAGE_HOME_URL } from '@design-system/feature/special-pages';
import { AuthTokens, SharedFeatAuthModule } from '@features/auth';
import { RootEffects, rootReducer, RootState } from '@features/root-store';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  BASE_PATH as HELP_PAGES_BASE_PATH,
  HELP_PAGES_CONFIG,
  HelpPagesConfig,
} from '@paldesk/design-system/feature/help-page';
import { BASE_PATH as USER_PROFILE_BASE_PATH } from '@paldesk/shared-lib/data-access/identity-service-generated';
import { BASE_PATH } from '@paldesk/shared-lib/data-access/smart-processes-service-generated';
import {
  GoogleAnalytics4MeasurementId,
  GoogleAnalytics4Module,
} from '@shared-lib/google-analytics';
import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpErrorInterceptor,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import { environment } from '../environments/environment';
import {
  BPM_API_MANAGEMENT_CONFIG,
  BpmApiManagementSubscriptionInterceptor,
} from './shared/interceptors/bpm-api-mngmt-subscription.interceptor';

interface State {
  root: RootState;
}

const coreReducers: ActionReducerMap<State, any> = {
  root: rootReducer,
};

@NgModule({
  imports: [
    // Store
    StoreModule.forRoot(coreReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([RootEffects]),
    StoreDevtoolsModule.instrument({
      name: 'Service Inspection',
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true,
    }),
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
    SharedFeatAuthModule.forRoot(),
    GoogleAnalytics4Module.forRoot({
      provide: GoogleAnalytics4MeasurementId,
      useFactory: () => environment.google_analytics_id,
    }),
  ],
  providers: [
    // HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BpmApiManagementSubscriptionInterceptor,
      multi: true,
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: BASE_PATH,
      useFactory: () =>
        environment.FEATUREFLAGS.use_app_gateway === 'true'
          ? environment.palfinger_app_gateway + '/smart-processes'
          : environment.palfinger_api_management + '/service-inspection',
    },
    // table settings
    {
      provide: USER_PROFILE_BASE_PATH,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    // bpm applications
    {
      provide: BPM_BASE_PATH,
      useFactory: () => environment.bpm_api,
    },
    {
      provide: BPM_API_MANAGEMENT_CONFIG,
      useFactory: () => ({
        subKey: environment.palfinger_bpm_api_management_subs_key,
        url: environment.bpm_api,
      }),
    },
    //help pages
    {
      provide: HELP_PAGES_BASE_PATH,
      useFactory: () => environment.help_pages_api,
    },
    // help pages home URL
    {
      provide: DS_SPECIAL_PAGE_HOME_URL,
      useFactory: () => environment.LINKS.HOME,
    },
    {
      provide: HELP_PAGES_CONFIG,
      useFactory: () =>
        ({
          pagePath: '/Help pages/Smart-Processes',
          projectName: 'Palfinger.Paldesk',
          wikiName: 'Palfinger.Paldesk.wiki',
          appTitle: 'Smart Processes',
        }) satisfies HelpPagesConfig,
    },
  ],
})
export class CoreModule {}
